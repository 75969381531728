jQuery(document).ready(function($) {

/* ====================== TESTIMONIALS CAROUSEL ======================  */
	var owl = $('.testimonials__slider').owlCarousel({
		dots: false,
		nav: false,
		loop: true,
		items: 1,
		autoplay:true,
		autoplayTimeout:5000,
	});

/* ====================== MOBILE MENU TOGGLE ======================  */
	$('.menu-toggle').on("click", function() {
		$(this).toggleClass('menu-toggle--active');
		$('.navigation').toggleClass('navigation--active');
		$('body').toggleClass('navopen');
		$(".header").toggleClass('header--navopen');
	})

/* ====================== ACCODRION INNER PAGE ======================  */
	$('.accordion .card__header').on("click", function() {
		var $this = $(this);

	    if ($this.next().hasClass('card__info--active')) {
	        $this.next().removeClass('card__info--active');
	        $this.next().slideUp(350);
	    } else {
	        $this.parent().parent().find('li .card__info').removeClass('card__info--active');
	        $this.parent().parent().find('li .card__info').slideUp(350);
	        $this.next().toggleClass('card__info--active');
	        // $this.next().slideToggle(350);
	    }
	})

/* ====================== TESTIMONIALS CAROUSEL ======================  */
	var owl = $('.masthead-slider').owlCarousel({
		dots: true,
		nav: false,
		loop: true,
		items: 1,
		autoplay:true,
		autoplayTimeout:7000,
	});

});
